export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: 'sounds/background.mp3'
    // },
    {
        name: 'fatherModel',
        type: 'gltfModel',
        path: 'models/father.glb'
    },
    {
        name: 'environmentMapTexture',
        type: 'texture',
        path: 'textures/environmentMap/hdri.jpeg'
    },
    // {
    //     name: 'displacementTexture',
    //     type: 'texture',
    //     path: 'textures/displacement.jpg'
    // }
]
