import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        this.scene.colorSpace = THREE.SRGBColorSpace

        this.setAmbientLight()
        this.setPointLight()
        this.setPointLightBack()
        this.setEnvironmentMap()

        this.setDebug()
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight('#ffffff', 0.2)
        this.scene.add(this.ambientLight)
    }

    setPointLight() {
        this.pointLight = new THREE.PointLight('#ffffff', 15, 100)
        this.pointLight.position.set(0.5, 1, 1)
        this.scene.add(this.pointLight)
    }

    setPointLightBack() {
        this.pointLight = new THREE.PointLight('#ffffff', 10, 100)
        this.pointLight.position.set(-0.5, 1.0, -1)
        this.scene.add(this.pointLight)
    }


    setEnvironmentMap()
    {
        /**
         * Environment Map parameters
         */
        this.environmentMap = {}
        this.environmentMap.intensity = 0.4

        /**
         * Texture settings
         */
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.colorSpace = THREE.SRGBColorSpace
        this.environmentMap.texture.mapping = THREE.EquirectangularReflectionMapping
        //this.environmentMap.texture.encoding = THREE.sRGBEncoding
        this.environmentMap.texture.generateMipmaps = false
        this.environmentMap.texture.minFilter = THREE.NearestFilter
        this.environmentMap.texture.magFilter = THREE.NearestFilter

        /**
         * Scene settings
         */
        //this.scene.environment = this.environmentMap.texture
        this.scene.background = this.environmentMap.texture
        this.scene.backgroundBlurriness = 0.045
        //this.scene.backgroundIntensity = 6.1
        //this.scene.envMapIntensity = 9.1

        this.environmentMap.updateMaterials = () =>
        {
            this.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
    }

    setDebug() {
        if(this.debug.active) {

        }
    }
}
