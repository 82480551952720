import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Father
{
    constructor() {
        this.experience = new Experience()
        this.debug = this.experience.debug
        this.scene = this.experience.scene
        this.time = this.experience.time
        this.camera = this.experience.camera
        this.renderer = this.experience.renderer.instance

        this.setModel()

        // //add cube
        // this.geometry = new THREE.BoxGeometry( 1, 1, 1 );
        // this.material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
        // this.cube = new THREE.Mesh( this.geometry, this.material );
        // this.scene.add( this.cube );
    }

    setModel() {
        this.model = this.experience.resources.items.fatherModel.scene

        // this.model.traverse((child) => {
        //    if (child.isMesh && child.name === 'Plane015') {
        //        this.glassMesh = child
        //    }
        // });

        this.model.scale.set(5, 5, 5)
        this.scene.add(this.model)
    }
}
