import Experience from '../Experience.js'
import Environment from './Environment.js'
import Father from "./Father.js";

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.camera = this.experience.camera;
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.html = this.experience.html
        this.sound = this.experience.sound
        this.debug = this.experience.debug.panel

        // Wait for resources
        this.resources.on('ready', () =>
        {
            //this.sound.createSounds();

            this.experience.time.start = Date.now()
            this.experience.time.elapsed = 0

            // Setup
            this.father = new Father()
            this.environment = new Environment()

            // Animation timeline
            this.animationPipeline();
        })
    }

    animationPipeline() {
        // if ( this.text )
        //     this.text.animateTextShow()

        if ( this.camera )
            this.camera.animateCameraPosition()
    }

    resize() {

    }

    update()
    {

    }
}
